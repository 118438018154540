import { useRef, useState, useEffect } from 'react';
import { Navbar, Nav, Button, Image } from 'react-bootstrap';
import { Link, animateScroll as scroll } from 'react-scroll';
import { Helmet } from "react-helmet";

import Sobre from '../src/pages/Sobre';
import Contato from '../src/pages/Contato';
import Metodologia from '../src/pages/Metodologia';
import Solucoes from '../src/pages/Solucoes';
import Home from '../src/pages/Home';
import WhatsappButton from '../src/components/WhatsappIcon';
import logoContato from '../src/assets/Contato/logo-contato.png';

import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {

  const [scrollReachedLimit, setScrollReachedLimit] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const limitPosition = 200;

      if (scrollPosition < limitPosition) {
        setScrollReachedLimit(true);
      } else {
        setScrollReachedLimit(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToRef = (ref) => {
    scroll.scrollTo(ref.current.offsetTop, {
      duration: 500,
      smooth: 'easeInOutQuart'
    });
  }

  const secao1Ref = useRef(null);
  const secao2Ref = useRef(null);
  const secao3Ref = useRef(null);
  const secao4Ref = useRef(null);
  const secao5Ref = useRef(null)

  return (
    <>

      <Helmet>
        <title>Gestão financeira para pequenas empresass</title>
        <meta
          name="description"
          content="Descubra nossas soluções inovadoras, metodologia eficaz para implantação de gestão financeira empresarial."
        />
        <meta
          name="keywords"
          content="Serviço, financeira ,contato, empresa, serviços, empresarial, organizar, empresas"
        />
        <meta name="author" content="Minha Empresa" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <Navbar collapseOnSelect variant="light" expand="lg" fixed='top'
        style={{ backgroundColor: scrollReachedLimit ? 'rgba(253,247,235,0.4)' : '#ffc600' }}>

        <Navbar.Brand href="/" style={{ padding: '0px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Image width="45" height="45" src={logoContato} className="d-inline-block align-top"
              alt="Logo" style={{ verticalAlign: 'middle', marginLeft: '10px' }} />
          </div>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Button variant="none" style={{ padding: '0px', margin: '0px', border: '0px' }}>
              <Link to="section1" onClick={() => scrollToRef(secao1Ref)} smooth={true} duration={500} spy={true} exact="true" offset={-70}>
                Home
              </Link>
            </Button>
            <Button variant="none" style={{ padding: '0px', margin: '0px', border: '0px' }}>
              <Link to="section2" onClick={() => scrollToRef(secao2Ref)} smooth={true} duration={500} spy={true} exact="true" offset={-70}>
                Sobre
              </Link>
            </Button>
            <Button variant="none" style={{ padding: '0px', margin: '0px', border: '0px' }}>
              <Link to="section3" onClick={() => scrollToRef(secao3Ref)} smooth={true} duration={500} spy={true} exact="true" offset={-70}>
                Soluções
              </Link>
            </Button>
            <Button variant="none" style={{ padding: '0px', margin: '0px', border: '0px' }}>
              <Link to="section4" onClick={() => scrollToRef(secao4Ref)} smooth={true} duration={500} spy={true} exact="true" offset={-70}>
                Metodologia
              </Link>
            </Button>
            <Button variant="none" style={{ padding: '0px', margin: '0px', border: '0px' }}>
              <Link to="section5" onClick={() => scrollToRef(secao5Ref)} smooth={true} duration={500} spy={true} exact="true" offset={-70}>
                Contato
              </Link>
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div id="section1" ref={secao1Ref}>
        <Home />
      </div >

      <div id="section2" ref={secao2Ref}>
        <Sobre />
      </div >

      <div id="section3" ref={secao3Ref}>
        <Solucoes />
      </div >

      <div id="section4" ref={secao4Ref}>
        <Metodologia />
      </div >

      <div id="section5" ref={secao5Ref}>
        <Contato />
      </div >

      <div>
        <WhatsappButton />
      </div>
    </>
  );
}

export default App;
